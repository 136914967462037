import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { DSDropdown, DSFlex, DSFormErrorText, DSInput, DSSpacer } from '@zoe/ds-web'
import styled from 'styled-components'
import { WEIGHT_SCREEN_INITIAL_VALUES } from '../individual-screens'

const WEIGHT_OPTIONS = [
  { value: 'lbs', label: 'lbs' },
  { value: 'kg', label: 'kg' },
  { value: 'stone', label: 'stone' },
]

const StyledDropDown = styled.div`
  min-width: 110px;
`

type WeightScreenFormBodyProps = {
  defaultWeightUnit: string
}

export const WeightScreenFormBody: React.FC<WeightScreenFormBodyProps> = ({ defaultWeightUnit }) => {
  const { errors, touched, resetForm } = useFormikContext<any>()
  const [selectedUnit, setSelectedUnit] = useState<string>(defaultWeightUnit)

  let selectedError, selectedTouched

  if (selectedUnit === 'stone') {
    selectedError = errors['weight_st'] || errors['weight_st_lbs']
    selectedTouched = touched['weight_st'] || touched['weight_st_lbs']
  } else {
    selectedError = errors[`weight_${selectedUnit}`]
    selectedTouched = touched[`weight_${selectedUnit}`]
  }

  const onSelect = (option: string) => {
    resetForm({ values: { ...WEIGHT_SCREEN_INITIAL_VALUES, ...{ weight_unit: option } } })
    setSelectedUnit(option)
  }

  return (
    <div>
      <DSFlex direction={'row'}>
        {selectedUnit === 'kg' && <DSInput name="weight_kg" placeholder="Add weight" type="number" step={0.1} />}
        {selectedUnit === 'lbs' && <DSInput name="weight_lbs" placeholder="Add weight" type="number" min={0} />}
        {selectedUnit === 'stone' && (
          <>
            <DSInput name="weight_st" placeholder="stones" type="number" min={0} />
            <DSSpacer size={12} direction="horizontal" />
            <DSInput name="weight_st_lbs" placeholder="lbs" type="number" min={0} max={13} />
          </>
        )}
        <DSSpacer size={12} direction="horizontal" />
        <StyledDropDown>
          <DSDropdown name="weight_unit" onSelect={onSelect} selected={selectedUnit} options={WEIGHT_OPTIONS} />
        </StyledDropDown>
      </DSFlex>
      {selectedError && selectedTouched && <DSFormErrorText>{selectedError}</DSFormErrorText>}
    </div>
  )
}
